<template>
  <div class="el_banner">
    <div class="banner">
      <div id="pano"></div>
      <div class="bannertxt">
        <div class="tit">中国石家庄人力资源服务产业园</div>
        <div class="txt">服务城市未来 服务区域发展</div>
        <a @click="windowOpen"
           class="mbtn big orange">园区全景展示</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      msg: "这是el_banner",
    };
  },
  mounted () {
    embedpano({
      swf: "//www.kuleiman.com/kuleiman.swf",
      xml: "./static/js/pano.xml",
      target: "pano",
      html5: "only+webgl",
      mobilescale: 1.0,
      mwheel: false,
      passQueryParameters: true,
    });
  },
  methods: {
    windowOpen () {
      window.open('https://www.kuleiman.com/145674/index.html', '_blank')
    }

  },
};
</script>

<style scoped lang="scss"></style>
