<template>
  <div class="wrapper">
    <div class="mtit1"
         style="margin-bottom:20px;">
      <h2 class="cn">入驻企业</h2>
      <div class="en">THE DISPLAY IN THE COMPANY</div>
      <div class="line"></div>
    </div>
    <div class="hinst-hd">
      <ul class="ulFlex">
        <li v-for="(item,index) in tabList"
            :key="index"
            :class="activeTab==item.itemValue?'active':''"
            @click="changeTab(item)">
          {{item.itemText}}
        </li>
      </ul>
    </div>
    <div class="hinst-bd">
      <ul>
        <li v-for="(item,index) in componeyList"
            :key="index"
            class="hinst-bd-child">
          <div class="img">
            <img :src="item.companyLogo" />
          </div>
          <div class="txt">
            <p>{{item.companyIntroduce}}</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="hinst-ft"
         v-if="type==1">
      <a @click="toRouter('enterprise')"
         class="mbtn white">更多企业</a>
    </div>

    <div class="hzqy-more"
         v-else>
      <a @click="toRouter('enterprise')"
         class="mbtn orange ful">查看全部</a>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      msg: '这是home',
      activeTab: 1,
      tabList: [],
      componeyList: [],

    }
  },
  props: {
    type: {
      default: 1,
      type: String
    }
  },
  mounted () {
    this.getCompanyTypeList();// 入住机构
  },
  methods: {
    changeTab (item) {
      this.activeTab = item.itemValue;
      this.getCompanyList(item.itemValue);
    },
    getCompanyTypeList () {//入住机构
      this.api.getCompanyTypeList().then(res => {
        this.tabList = res.data.result;
        this.activeTab = this.tabList[0].itemValue;
        this.getCompanyList(this.tabList[0].itemValue)
      })
    },
    getCompanyList (mainBusiness) {
      this.api.getCompanyList({ pageNo: 1, pageSize: 20, mainBusiness: mainBusiness }).then(res => {
        console.log(res, 765434567)
        this.componeyList = res.data.result.records;
      })
    },
    toRouter (routerName) {
      this.newRouter('/' + routerName)
    },
  }
}
</script>
<style lang="scss">
@media only screen and (min-width: 1201px) {
  .ulFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      flex: 1;
    }
  }

}
@media screen and (max-width: 1201px) {
 .hinst-bd-child.hinst-bd-child{
      width: 100%;
  }
}
</style>