<template>
  <div class="home">
    <banner />

    <div class="hbox">
      <div class="hserve wrapper">
        <ul>
          <li v-for="(item,index) in hserveList"
              :key="index">
            <div class="hserve-img"><img :src="item.imgUrl" /></div>
            <h3 class="hserve-tit">{{item.title}}</h3>
            <div class="hserve-txt"
                 v-if="max1201"
                 v-html="item.content"></div>
            <div class="hserve-txt"
                 v-else
                 v-html="item.content2"></div>
            <a class="mbtn small"
               @click="toRouter(item.applyRouter)">立即申请</a>
          </li>
        </ul>
      </div>
      <div class="habout wrapper">
        <h2 class="habout-tit">园区概况</h2>
        <div class="habout-txt">
          中国石家庄人力资源服务产业园成立于2019年6月，于2021年1月12日获批国家级产业园，总面积21万平方米，<br />
          总投资约20亿，以“一园多点，一园多能”规模布局，坚持“战略性集聚和系统性开发”发展理念，<br />
          拓展人力资本产业，依托六大服务功能，为机构赋能、为人才赋能，促劳动力就业、促人才配置、促产业转型。<br />
          园区重点发展新兴和高端业态，围绕高新区产业发展需求，产业园整合人力资源公共服务要素，<br />
          实行“店小二”式全程便捷代办，“经理人”式全程跟进咨询，“清单” 式全程价值分享的一体化人才服务。
        </div>
        <a class="mbtn small white"
           @click="toRouter('introductionPark')">了解更多</a>
      </div>

      <div class="hnotice wrapper">
        <div class="hnotice-l">
          <img src="../../assets/images/icon-message.png" />滚动消息
        </div>
        <div class="hnotice-r swiper-container">
          <ul class="swiper-wrapper">
            <li class="swiper-slide flexCon"
                v-for="(item,index) in hnoticeList"
                :key="index"
                @click="toRouter('article',item)">
              <h3 class="tit">{{item.title}}</h3>
              <span class="time">{{item.releaseTime}}</span>
            </li>
          </ul>

        </div>
      </div>

      <div class="hnews wrapper">
        <div class="hnews-box left">
          <div class="mtit hnews-hd">
            <h2 class="cn">精彩活动</h2>
            <div class="en">exciting activities</div>
          </div>
          <div class="hnews-bd">
            <ul class="mnewslist">
              <li v-for="(item,index) in hnewsList"
                  :key="index">
                <a @click="toRouter('article',item)">
                  <div class="img"><img :src="item.cover"
                         style="height:80px" /></div>
                  <div class="txts">
                    <h3 class="tit">{{item.title}}</h3>
                    <div class="info">
                      {{item.articleAbstract}}
                    </div>
                    <div class="time">{{item.releaseTime}}</div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="hnews-ft">
            <a @click="toRouter('information',{type:2,title:'精彩活动',en:'exciting activities'})"
               class="mbtn">更多活动</a>
          </div>
        </div>
        <div class="hnews-box right">
          <div class="mtit hnews-hd">
            <h2 class="cn">热点资讯</h2>
            <div class="en">Popular informationes</div>
          </div>
          <div class="hnews-bd">
            <ul class="mnewslist1">
              <li v-for="(item,index) in hnewsBoxList"
                  :key="index">
                <a @click="toRouter('article', item)">
                  <h3 class="tit">{{item.title}}</h3>
                  <span class="time">{{item.releaseTime}}</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="hnews-ft">
            <a @click="toRouter('information',{type:3,title:'热点资讯',en:'Popular informationes'})"
               class="mbtn">更多资讯</a>
          </div>
        </div>

      </div>
    </div>

    <div class="himport">
      <div class="wrapper">
        <div class="himport-tit">
          <div class="hd">
            <div class="cn">党建要闻</div>
            <div class="en">Important news</div>
            <div class="img"><img src="../../assets/images/stars.png" /></div>
          </div>
          <div class="ft">
            <a @click="toRouter('information',{type:4,title:'党建要闻',en:'NEWS OF THE PARTY'})"
               class="mbtn red">更多要闻</a>
          </div>
        </div>
        <div class="himport-main">
          <div class="himport-img swiper-container">
            <ul class="swiper-wrapper">
              <li class="swiper-slide"
                  v-for="(item,index) in newParty"
                  :key="index"
                  @click="toRouter('article',item)">
                <img class="img"
                     @click="toRouter('article',item)"
                     :src="item.cover" />
              </li>
            </ul>
            <div class="swiper-pagination"></div>
          </div>
          <div class="himport-list">
            <ul class="mnewslist1">
              <li v-for="(item,index) in newParty"
                  :key="index">
                <a @click="toRouter('article',item)">
                  <h3 class="tit">{{item.title}}</h3>
                  <span class="time">{{item.releaseTime}}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="hinst">
      <componeny type="1" />
    </div>
  </div>
</template>

<script>
import banner from './banner.vue'
import Swiper from "@/libs/js/swiper.min";
import componeny from '../components/componeny.vue'
export default {
  data () {
    return {
      msg: '这是home',
      hserveList: [
        {
          imgUrl: require('../../assets/images/icon-sqrz.png'),
          title: '申请入驻',
          content: '政策解析 咨询辅导<br />税收补贴 创新奖励<br />配套服务 政策兑现',
          content2: '政策解析 咨询辅导 税收补贴 创新奖励 配套服务 政策兑现',
          applyRouter: 'investment',
        },
        {
          imgUrl: require('../../assets/images/icon-yhzc.png'),
          title: '优惠政策',
          content: '入驻流程 入驻条件<br />入驻规章 入驻审批',
          content2: '入驻流程 入驻条件 入驻规章 入驻审批',
          applyRouter: 'process',
        },
        {
          imgUrl: require('../../assets/images/icon-yqfw.png'),
          title: '园区服务',
          content: '综合服务 供需服务<br />党建服务 媒体服务<br />物业服务 经营服务',
          content2: '综合服务 供需服务 党建服务 媒体服务 物业服务 经营服务',
          applyRouter: 'parkservices',
        },
        {
          imgUrl: require('../../assets/images/icon-rcfw.png'),
          title: '人才服务',
          content: '柔性招引 创业孵化<br />成果转化 项目对接<br />平台搭建 活动交流',
          content2: '柔性招引 创业孵化 成果转化 项目对接 平台搭建 活动交流',
          applyRouter: 'personnel',
        },
        {
          imgUrl: require('../../assets/images/icon-gzz.png'),
          title: '工作站',
          content: '县域招才 企业培训<br />公共服务 创业孵化',
          content2: '县域招才 企业培训 公共服务 创业孵化',
          applyRouter: 'workstation',
        },
      ],
      hnoticeList: [
      ],
      hnewsList: [
      ],
      hnewsBoxList: [
      ],

      newParty: [],
      max1201: false,
    }
  },
  components: {
    banner,
    componeny
  },
  mounted () {


    window.onresize = () => {
      this.sizeChange();
    }
    this.sizeChange();


    this.getArticleList();
    this.getHnewsList();//精彩活动
    this.getHnewsBoxList();//热点资讯
    this.getHnoticeList();//滚动消息
  },
  methods: {
    sizeChange () {
      if (document.body.offsetWidth > 1201) {
        this.max1201 = true;
      } else {
        this.max1201 = false;
      }
    },
    changeTab (item) {
      this.activeTab = item.itemValue;
      this.getCompanyList(item.itemValue);
    },
    toRouter (routerName, item) {
      // document.body.scrollTop = document.documentElement.scrollTop = 0
      let query = {};
      if (routerName === 'information') { // 党建要闻
        query = {
          type: item.type,
          title: item.title,
          en: item.en
        }
      } else if (routerName === 'article') {// 去文章详情
        query = {
          id: item.id
        }
      }

      // this.$router.push({ name: routerName,query:{...query} })
      this.newRouter('/' + routerName, query)
    },
    getArticleList () {
      this.api.getArticleList({ pageNo: 1, pageSize: 5, type: 4 }).then(res => {
        this.newParty = res.data.result.records;
        this.$nextTick(() => {
          new Swiper(".himport-img", {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            autoplayDisableOnInteraction: false,
            autoplay: 5000,
            loop: true,
          });
        })

      })
    },
    getHnewsList () {
      this.api.getArticleList({ pageNo: 1, pageSize: 3, type: 2 }).then(res => {
        this.hnewsList = res.data.result.records;
      })
    },
    getHnewsBoxList () {
      this.api.getArticleList({ pageNo: 1, pageSize: 5, type: 3 }).then(res => {
        this.hnewsBoxList = res.data.result.records;
      })
    },
    getHnoticeList () {
      this.api.getArticleList({ pageNo: 1, pageSize: 10, type: 3 }).then(res => {
        this.hnoticeList = res.data.result.records;
        this.$nextTick(() => {
          new Swiper(".hnotice-r", {
            direction: 'vertical',
            autoplayDisableOnInteraction: false,
            autoplay: 3000,
            loop: true,
          });
        })
      })
    },



  }
}
</script>
<style scoped lang="scss">
.flexCon {
  cursor: pointer;
  .tit {
    width: calc(100% - 110px);
  }
  .time {
    width: 110px;
  }
}
</style>
<style>
</style>
